import { Button, Collapse, Divider, Drawer } from 'antd'
import api from 'api'
import LoadingIndicator from 'components/LoadingIndicator'
import { useActiveBannerStore, useBannersStore } from 'containers/Banners/store'
import useArrowNavigation from 'hooks/useArrowNavigation'
import useRetailers from 'hooks/useRetailers'
import React, {
  forwardRef,
  Suspense,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { Banner } from 'types'
import { confirmResetForm } from 'utils/alerts'
import BannerDetailsTable from '../BannerDetailsTable'
import BannerProductsTable from '../BannerProductsTable'
import BannerStatusMenu from '../BannerStatusMenu'
import BannersViewer from '../BannersViewer'
import Navigation from '../Navigation'
import InsertionForm from './InsertionForm'
import useForm from './useForm'
import useNavigation from './useNavigation'

const { Panel } = Collapse

const InsertionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-top: 15px;
  border-bottom: 1px solid #eee;
  font-size: 18px;
`

const InsertionDrawer = forwardRef<
  {
    handleClose: () => void
  },
  {
    onClose: () => void
    onInsert: (data: any) => Promise<any>
  }
>(({ onInsert, onClose }, ref) => {
  const [statusChanging, setStatusChanging] = useState(false)
  const tableRef = useRef<{ downloadExcel: () => void }>(null)

  const { data: retailers } = useRetailers()

  const activeBannerIds = useActiveBannerStore((state) => state.activeBannerIds)

  const banners = useBannersStore(
    (state) =>
      state.banners.filter((b) => activeBannerIds.includes(b.id)) as Banner[],
  )

  const removeBanner = useBannersStore((state) => state.removeBanner)

  const selectedCountries = useMemo(() => {
    if (retailers && banners.length) {
      const _countries = banners.map(
        (banner) => retailers[banner.retailer_id].Country,
      )

      return _countries.reduce((accumulator, currentValue) => {
        if (!accumulator.includes(currentValue)) {
          accumulator.push(currentValue)
        }
        return accumulator
      }, [])
    }
    return []
  }, [banners, retailers])

  const { isModalVisible, formik } = useForm(
    banners,
    onInsert,
    selectedCountries,
  )
  const { handleNavigation, onNext } = useNavigation(formik)

  const handleSubmit = useCallback(
    (action: string) => async () => {
      try {
        await formik.submitForm()
        action === 'next' ? onNext() : onClose()
      } catch (error) {}
    },
    [formik, onClose, onNext],
  )

  const handleStatusChange = async (status: number) => {
    setStatusChanging(true)
    await api.banners.status(
      status,
      banners.map((banner) => banner.image_hash_id),
    )
    removeBanner(banners)
    setStatusChanging(false)
    onClose()
  }
  const minStatus = useMemo(
    () =>
      banners.reduce(
        (min, current) => (current.status < min ? current.status : min),
        0,
      ),
    [banners],
  )

  const handleClose = useCallback(() => {
    if (formik.dirty) {
      confirmResetForm(() => {
        formik.resetForm()
        onClose()
      })
    } else {
      onClose()
    }
  }, [formik, onClose])

  const handleDownload = (event: any) => {
    event.stopPropagation()
    if (tableRef.current) {
      tableRef.current.downloadExcel()
    }
  }

  useArrowNavigation(
    isModalVisible ? 'confirmation-modal' : 'form-tabs',
    true,
    true,
    ({ name, value }) => {
      if (![name, value].includes(undefined)) {
        formik.setFieldValue(name, value)
      }
    },
    true,
  )

  useImperativeHandle(ref, () => ({
    handleClose,
  }))

  useEffect(() => {
    const modalContainer: any = document.querySelector('.ant-modal')
    if (modalContainer) {
      modalContainer.focus()
    }
  }, [])

  return (
    <div id="form-tabs">
      <Collapse defaultActiveKey={['1', '2', '4']} bordered={false}>
        <InsertionHeader>
          <BannerStatusMenu
            currentStatus={minStatus}
            loading={statusChanging}
            onClick={handleStatusChange}
          />
        </InsertionHeader>
        <Panel header="Images" key="1">
          <BannersViewer banners={banners} />
        </Panel>
        <Panel header="Form" key="4" disabled>
          <InsertionForm
            onSubmit={handleSubmit}
            formik={formik}
            countries={selectedCountries}
          />
        </Panel>
        <Navigation disabled={false} handleNavigation={handleNavigation} />
        <Divider />
        <Panel header="Details" key="2">
          {banners.length <= 1 && (
            <BannerDetailsTable
              image_hash_id={banners[0]?.image_hash_id}
              retailer_id={banners[0]?.retailer_id}
            />
          )}
        </Panel>
        <Panel
          header={
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ marginBottom: 0 }}>Products</p>
              <Button onClick={handleDownload}> Download</Button>
            </div>
          }
          key="3"
        >
          {banners.length <= 1 && (
            <BannerProductsTable
              ref={tableRef}
              image_hash_id={banners[0]?.image_hash_id}
              retailer_id={banners[0]?.retailer_id}
            />
          )}
        </Panel>
        {banners.length <= 1 && <></>}
      </Collapse>
    </div>
  )
})
const InsertDrawerWrapper: React.FC<{
  open: boolean
  onClose: () => void
  onInsert: (data: any) => Promise<any>
}> = ({ open, onClose, onInsert }) => {
  const callbackRef = useRef<any>(null)

  const handleClose = () => {
    if (callbackRef.current) {
      callbackRef.current.handleClose()
    }
  }
  return (
    <Drawer
      title="Banners Insertion"
      visible={open}
      onClose={handleClose}
      width={920}
    >
      <Suspense fallback={<LoadingIndicator />}>
        <InsertionDrawer
          ref={callbackRef}
          onInsert={onInsert}
          onClose={onClose}
        />
      </Suspense>
    </Drawer>
  )
}
export default InsertDrawerWrapper
