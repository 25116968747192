import { Table } from 'antd'
import useBannerProducts from 'hooks/useBannerProducts'
import React, { forwardRef, useImperativeHandle } from 'react'
import * as XLSX from 'xlsx'

const { Column } = Table

const BannerProductsTable = forwardRef<
  unknown,
  { image_hash_id: string; retailer_id: number }
>(({ image_hash_id, retailer_id }, ref) => {
  const { data: bannerDetails, isLoading } = useBannerProducts(
    image_hash_id,
    retailer_id,
  )

  useImperativeHandle(ref, () => ({
    downloadExcel: () => {
      if (!bannerDetails || bannerDetails.length === 0) return

      const dataForExport = bannerDetails.map((item: any) => ({
        Name: item.product_name,
        EAN: item.product_ean,
        'Ref pe': item.product_id,
        RPC: item.rpc,
        Image: item.product_image,
      }))

      const worksheet = XLSX.utils.json_to_sheet(dataForExport)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, 'BannerProducts')

      const fileName = `${image_hash_id}-${retailer_id}-${new Date().toISOString()}.xlsx`

      XLSX.writeFile(workbook, fileName)
    },
  }))

  return (
    <Table
      dataSource={bannerDetails}
      size="small"
      loading={isLoading}
      rowKey={(record, index) => `${index}`}
    >
      <Column title="Name" dataIndex="product_name" key="product_name" />
      <Column title="EAN" dataIndex="product_ean" key="product_ean" />
      <Column title="Ref pe" dataIndex="product_id" key="product_id" />
      <Column title="RPC" dataIndex="rpc" key="rpc" />
      <Column
        title="Image"
        dataIndex="product_image"
        key="screenshot_image"
        render={(url) =>
          url ? (
            <a href={url} target="_blank" rel="noopener noreferrer">
              <img src={url} style={{ maxWidth: '350px', height: 'auto' }} />
            </a>
          ) : (
            'N/A'
          )
        }
      />
    </Table>
  )
})

export default BannerProductsTable
